import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gmp: {},
  selectedUIAllFilters: {}
};

const gmpSlice = createSlice({
  name: "gmp",
  initialState,
  reducers: {
    previousStep(state) {
      state.currentStep = state.currentStep - 1;
    },
    nextStep(state) {
      state.currentStep = state.currentStep + 1;
    },
    nextStepIndex(state) {
      state.stepIndex = state.stepIndex + 1;
    },
    setIsSaveAndNextDisabled(state, action) {
      const { isDisabled } = action.payload;

      state.isSaveAndNextDisabled = isDisabled;
    },
    setrulesMapData(state, action) {
      const { rulesMapData } = action.payload;

      state.gmp = { ...state.gmp, rulesMapData: rulesMapData };
    },
    setGMPDetails(state, action) {
      const { gmpData } = action.payload;

      state.gmp = { ...state.gmp, gmpDataSet: gmpData };
    },
    setGMPHierarchy(state, action) {
      const { gmpHierarchyData } = action.payload;

      state.gmp = { ...state.gmp, gmpHierarchyDataSet: gmpHierarchyData };
    },
    setselectedUIAllFilters(state, action) {
      const { selectedUIAllFilters } = action.payload;
      state.selectedUIAllFilters = selectedUIAllFilters;
    },
    setSelectedGMPRowData(state, action) {
      const { rowData } = action.payload;

      state.gmp = { ...state.gmp, rowData: rowData };
    },
    setSelectedGMPRowIndex(state, action) {
      const { rowDataIndex } = action.payload;

      state.gmp = { ...state.gmp, rowIndex: rowDataIndex };
    },
    setGMPEditData(state, action) {
      const { gmpEditData } = action.payload;

      state.gmp = { ...state.gmp, gmpEditData: gmpEditData };
    },
    setVariableTypeValue(state, action) {
      const { variableType } = action.payload;

      state.gmp = { ...state.gmp, gmpVariableType: variableType };
    },
    setIsVariableApply(state, action) {
      const { isVariableApply } = action.payload;

      state.gmp = { ...state.gmp, isVariableApply: isVariableApply };
    },
    dropEngagementState(state) {},
  },
  extraReducers(builder) {},
});

export const {
  previousStep,
  nextStep,
  nextStepIndex,
  setIsSaveAndNextDisabled,
  dropEngagementState,
  setrulesMapData,
  setGMPDetails,
  setGMPHierarchy,
  setSelectedGMPRowData,
  setSelectedGMPRowIndex,
  setGMPEditData,
  setVariableTypeValue,
  setIsVariableApply,
  setselectedUIAllFilters
} = gmpSlice.actions;

export default gmpSlice.reducer;
