import "./Select.scss";
import {
  MotifSelect,
  MotifOption,
  MotifFormField,
  MotifErrorMessage,
} from "@ey-xd/motif-react";

export function Select({
  onChange,
  value = undefined,
  id = crypto.randomUUID(),
  className,
  required = false,
  disabled = false,
  placeholder = "",
  options = [],
  multiple = false,
  visibleOptions = 3,
  isError,
  errorMessage,
  disableErrorPlaceholder = true,
  showSelectAllButton = false,
  ...other
}) {
  return (
    <MotifFormField className="decarb-select">
      <MotifSelect
        {...other}
        id={id}
        onChange={onChange}
        value={multiple ? value : value?.toString()}
        className={` ${className}`}
        required={required}
        disabled={disabled}
        multiple={multiple}
        visibleOptions={visibleOptions}
        showSelectAllButton={showSelectAllButton}
        placeholder={placeholder}
        filter
        triggerButtonProps={{
          "aria-describedby": id + "-error-message",
        }}
      >
        {options &&
          options.map((option) => (
            <MotifOption
              key={crypto.randomUUID()}
              value={option?.id ? option.id.toString() : option}
              selected={option?.selected ?? false}
            >
              {option?.name ? option.name : option}
            </MotifOption>
          ))}
      </MotifSelect>
      {isError ? (
        <MotifErrorMessage
          className="decarb-input-error-message"
          id={id + "-error-message"}
        >
          {errorMessage}
        </MotifErrorMessage>
      ) : !disableErrorPlaceholder ? (
        <div className="decarb-input-hide-error">No Error</div>
      ) : (
        <></>
      )}
    </MotifFormField>
  );
}
