import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { useDispatch } from "react-redux";
import { Button, Table } from "../../components";
import {
  inputCustomRenderer,
  customButtonRenderer,
  customTextRenderer,
} from "./InputCutomRenderer";
import "./ViewEngagement.scss";
import { useEffect, useState } from "react";
import {
  GetAllEngagements,
  updateEngagement,
} from "../../store/services/engagement.service";
import EngagementLink from "./customEngagementLink";
import { useSelector } from "react-redux";
import { AddIcon, ArrowRightIcon } from "../../icons";
import {
  TableHeaderClientName,
  TableHeaderEngagementCode,
  TableHeaderEngagementName,
  TableHeaderEngagementStatus,
  CreateNewEngBtn,
  engDash,
  extUserDashboardM1Description,
  extUserDashboardM4Description,
  extUserDashboardM3Description,
  extUserDashboardM2Description,
} from "../../util/constants";
import { ViewEngagementDeleteModal } from "./ViewEngagementDeleteModal";
import {
  MotifCard,
  MotifCardFooter,
  MotifCardBody,
  MotifCardHeader,
} from "@ey-xd/motif-react";
import { SelectRegionModal } from "../SelectRegionModal/SelectRegionModal";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";
import { dropEngagementState } from "../../store/slices/engagementSlice";
import { useRegRedirectionAuthToken } from "../../hooks/useAppRedirectionToken";

export function ViewEngagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const steps = useSelector((state) => state.stepper.steps);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const regionList = useSelector((state) => state.engagement.regionList);
  const engagement = useSelector((state) => state.engagement.engagement);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSelectRegionVisible, setIsSelectRegionVisible] = useState(false);
  const [engagementToDelete, setEngagementToDelete] = useState("");
  const user = useSelector((state) => state.user.user);
  const [isDataLoadInProgress, setIsDataLoadInProgress] = useState(false);
  const [engagementData, setEngagementData] = useState([]);
  const [externalFlag, setExternalFlag] = useState([0]);
  const token = useRegRedirectionAuthToken();

  /* istanbul ignore next */
  function handleClick() {
    setIsSelectRegionVisible(true);
  }

  async function fetchData() {
    setIsDataLoadInProgress(true);
    dropEngagementState();
    try {
      const request = {
        email: user.username,
        token: token
      };
      const data = await GetAllEngagements({ request });
      setEngagementData(data[0]);
      setExternalFlag(data[1]);
      setRowData(data[0]);
    } catch (error) {
      console.error(error);
    }

    setIsDataLoadInProgress(false);
  }

  useEffect(() => {
    if (regionList.length && steps.length) {
      setIsDataLoadInProgress(true);
      fetchData();
      dropEngagementState();
    }
  }, [regionList.length, steps.length]);
  useEffect(() => {
    dropEngagementState();
  }, []);

  /* istanbul ignore next */
  function onDeleteEngagementClick(id) {
    setIsModalVisible(true);
    setEngagementToDelete(id);
  }

  /* istanbul ignore next */
  function onDeleteEngagementClose() {
    setIsModalVisible(false);
    setEngagementToDelete("");
  }

  /* istanbul ignore next */
  async function onDeleteEngagementConfirm() {
    const item = rowData.find((item) => item.id === engagementToDelete);
    item.activeFlag = 0;
    item.updatedDate = new Date().toLocaleString();

    const request = {
      id: engagementToDelete,
      engagementName: item.engagementName,
      engagementCode: item.engagementCode,
      clientName: item.clientName,
      createdBy: item.createdBy,
      activeFlag: item.activeFlag,
      updatedDate: item.updatedDate,
      updatedBy: user.username,
    };

    var apiRegionUrl = regionList.find((x) => x.id === item.regionID).apiUrl;

    await updateEngagement({ request, apiRegionUrl });

    setIsModalVisible(false);
    setEngagementToDelete("");

    const engagementToDeleteIndex = rowData.findIndex(
      (item) => item.id === engagementToDelete
    );
    rowData[engagementToDeleteIndex] = item;
    setRowData(
      rowData.sort((a, b) => {
        return b.activeFlag - a.activeFlag;
      })
    );
  }

  const renderCards = () => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return engagementData?.map((element) => {
      return (
        <div className="motif-card-head">
          <div className="dashboard-engagement-name">
            <h2>{element.engagementName}</h2>
          </div>

          <div className="dashboard-engagement-name">
            <h4>
              <span>{"Last updated : "}</span>
              {new Date(element.updatedDate).toLocaleDateString(
                "en-US",
                options
              )}
            </h4>
          </div>
          <div className="dashboard-cards">
            <MotifCard
              className={`dashboards-card-home ${
                element.setupStepID < 1 ? "dashboards-card-home-disabled" : ""
              }`}
            >
              <MotifCardHeader className="dashboards-card-home-header">
                <h3>Manage</h3>
              </MotifCardHeader>
              <div className="dashboard-cards-description-body">
                <MotifCardBody>
                  <span className="dashboard-cards-description-body-label">
                    {extUserDashboardM1Description}
                  </span>
                  <img
                    src={require("../../assets/Images/M1.png")}
                    alt="Emission Data"
                    loading="lazy"
                  />
                </MotifCardBody>
              </div>
              <MotifCardFooter>
                <button
                  className="transparent-button"
                  onClick={() => {
                    navigate(`/${routes.powerBI}`, {
                      state: {
                        datasetId: process.env.REACT_APP_POWER_BI_DATASET_ID_M1,
                        reportId: process.env.REACT_APP_POWER_BI_REPORT_ID_M1,
                        engagementId: element.id,
                        engagementCode: element.engagementCode,
                        regionId: selectedRegion.id,
                      },
                      gestureEnabled: false,
                    });
                  }}
                >
                  {"Access dashboard "}
                  <span>
                    <ArrowRightIcon />
                  </span>
                </button>
              </MotifCardFooter>
            </MotifCard>
            <MotifCard
              className={`dashboards-card-home ${
                element.setupStepID < 2 ? "dashboards-card-home-disabled" : ""
              }`}
            >
              <MotifCardHeader className="dashboards-card-home-header">
                <h3>Forecast</h3>
              </MotifCardHeader>
              <div className="dashboard-cards-description-body">
                <MotifCardBody>
                  <span className="dashboard-cards-description-body-label">
                    {extUserDashboardM2Description}
                  </span>
                  <img
                    src={require("../../assets/Images/M2.png")}
                    alt="Emission Data"
                    loading="lazy"
                  />
                </MotifCardBody>
              </div>
              <MotifCardFooter>
                <button
                  className="transparent-button"
                  onClick={() => {
                    navigate(`/${routes.powerBI}`, {
                      state: {
                        datasetId: process.env.REACT_APP_POWER_BI_DATASET_ID_M2,
                        reportId: process.env.REACT_APP_POWER_BI_REPORT_ID_M2,
                        engagementId: element.id,
                        engagementCode: element.engagementCode,
                        regionId: selectedRegion.id,
                      },
                      gestureEnabled: false,
                    });
                  }}
                >
                  {"Access dashboard "}
                  <span>
                    <ArrowRightIcon />
                  </span>
                </button>
              </MotifCardFooter>
            </MotifCard>
            <MotifCard
              className={`dashboards-card-home ${
                element.setupStepID < 3 ? "dashboards-card-home-disabled" : ""
              }`}
            >
              <MotifCardHeader className="dashboards-card-home-header">
                <h3>Analyse</h3>
              </MotifCardHeader>
              <div className="dashboard-cards-description-body">
                <MotifCardBody>
                  <span className="dashboard-cards-description-body-label">
                    {extUserDashboardM3Description}
                  </span>
                  <img
                    src={require("../../assets/Images/M3.png")}
                    alt="Emission Data"
                    loading="lazy"
                  />
                </MotifCardBody>
              </div>
              <MotifCardFooter>
                <button
                  className="transparent-button"
                  onClick={() => {
                    navigate(`/${routes.powerBI}`, {
                      state: {
                        datasetId: process.env.REACT_APP_POWER_BI_DATASET_ID_M3,
                        reportId: process.env.REACT_APP_POWER_BI_REPORT_ID_M3,
                        engagementId: element.id,
                        engagementCode: element.engagementCode,
                        regionId: selectedRegion.id,
                      },
                      gestureEnabled: false,
                    });
                  }}
                >
                  {"Access dashboard "}
                  <span>
                    <ArrowRightIcon />
                  </span>
                </button>
              </MotifCardFooter>
            </MotifCard>
            <MotifCard
              className={`dashboards-card-home ${
                element.setupStepID < 4 ? "dashboards-card-home-disabled" : ""
              }`}
            >
              <MotifCardHeader className="dashboards-card-home-header">
                <h3>Simulate</h3>
              </MotifCardHeader>
              <div className="dashboard-cards-description-body">
                <MotifCardBody>
                  <span className="dashboard-cards-description-body-label">
                    {extUserDashboardM4Description}
                  </span>
                  <img
                    src={require("../../assets/Images/M4.png")}
                    alt="Emission Data"
                    loading="lazy"
                  />
                </MotifCardBody>
              </div>
              <MotifCardFooter>
                <button
                  className="transparent-button"
                  onClick={() => {
                    navigate(`/${routes.powerBI}`, {
                      state: {
                        datasetId: process.env.REACT_APP_POWER_BI_DATASET_ID_M4,
                        reportId: process.env.REACT_APP_POWER_BI_REPORT_ID_M4,
                        engagementId: element.id,
                        engagementCode: element.engagementCode,
                        regionId: selectedRegion.id,
                      },
                      gestureEnabled: false,
                    });
                  }}
                >
                  {"Access dashboard "}
                  <span>
                    <ArrowRightIcon />
                  </span>
                </button>
              </MotifCardFooter>
            </MotifCard>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="view-engagement">
      <div className="view-engagement-header">
        <div className="view-engagement-header-h">
          <span className="view-engagement-header-title">{engDash}</span>
        </div>
        {externalFlag === 0 && (
          <Button
            onClick={handleClick}
            className={"view-engagement-header-new-project"}
            variant="primary-alt"
          >
            <AddIcon />
            {CreateNewEngBtn}
          </Button>
        )}
      </div>
      <LoadingIndicatorEmbed show={isDataLoadInProgress}>
        <div className="view-engagement-table">
          {externalFlag === 0 && (
            <Table
              colDefs={[
                {
                  field: "engagementName",
                  cellRenderer: EngagementLink,
                  headerName: TableHeaderEngagementName,
                  headerTooltip: TableHeaderEngagementName,
                  sortable: true,
                  flex: 0.5,
                  suppressMovable: true,
                  pinned: "left",
                },
                {
                  field: "engagementCode",
                  headerName: TableHeaderEngagementCode,
                  headerTooltip: TableHeaderEngagementCode,
                  flex: 0.5,
                  minWidth: 200,
                  cellRenderer: customTextRenderer,
                  suppressMovable: true,
                },
                {
                  field: "clientName",
                  headerName: TableHeaderClientName,
                  headerTooltip: TableHeaderClientName,
                  flex: 0.4,
                  minWidth: 200,
                  cellRenderer: customTextRenderer,
                  suppressMovable: true,
                },
                {
                  field: "regionName",
                  headerName: "Hosting location",
                  headerTooltip: "Hosting location",
                  flex: 0.4,
                  minWidth: 150,
                  suppressMovable: true,
                },
                {
                  field: "engagementStatus",
                  cellRenderer: inputCustomRenderer,
                  cellRendererParams: () => {
                    return {
                      steps: steps,
                    };
                  },
                  headerComponentParams: {
                    enableSorting: false,
                    showFilter: false,
                  },
                  headerName: TableHeaderEngagementStatus,
                  headerTooltip: TableHeaderEngagementStatus,
                  flex: 1.5,
                  minWidth: 600,
                  suppressMovable: true,
                },
                {
                  cellRenderer: customButtonRenderer,
                  cellRendererParams: () => {
                    return {
                      onDeleteEngagementClick: onDeleteEngagementClick,
                    };
                  },
                  field: "actions",
                  headerName: "",
                  width: 80,
                },
              ]}
              rowData={rowData}
              rowClassRules={{
                "view-engagement-closed-project": (params) => {
                  return params.data.activeFlag === 0;
                },
              }}
              draggable={false}
              loading={isDataLoadInProgress}
            />
          )}

          {externalFlag === 1 && (
            <div className="engagement-dashboard-fullscreen">
              {renderCards()}
            </div>
          )}
        </div>
      </LoadingIndicatorEmbed>
      <ViewEngagementDeleteModal
        isModalVisible={isModalVisible}
        onClose={onDeleteEngagementClose}
        onConfirm={onDeleteEngagementConfirm}
      />
      <SelectRegionModal
        isModalVisible={isSelectRegionVisible}
        onClose={() => setIsSelectRegionVisible(false)}
      />
    </div>
  );
}
