import { DECARB_LIBRARY_URL } from "./endpoints/decarbLibrary";
import { getListCrossRegion, post } from "./base.service";

async function GetAllProjectlibraryDetails({ request }) {
  // return await get(`${DECARB_LIBRARY_URL.GetAllProjectlibraryDetails}`);
  return await getListCrossRegion(
    `${DECARB_LIBRARY_URL.GetAllProjectlibraryDetails}` , request
  );
}

async function GetBusinessCaseSummaryData({ request }) {
  return await post(
    // `${DECARB_LIBRARY_URL.GetBusinessCaseSummaryData}?ProjectID=${request.projectId}`
    `/WebApi/Invoke`, {
      ActionVerb: "GET",
      ControllerName: "DecarbLibrary",
      ActionName: DECARB_LIBRARY_URL.GetBusinessCaseSummaryData,
      QueryString: 'ProjectID=' + request.projectId,
      Data: ""
    }
  );
}

async function GetProjectBusinessRulesData({ request }) {
  return await post(
    // `${DECARB_LIBRARY_URL.GetProjectBusinessRulesData}?ProjectID=${request.projectId}`
    `/WebApi/Invoke`, {
      ActionVerb: "GET",
      ControllerName: "DecarbLibrary",
      ActionName: DECARB_LIBRARY_URL.GetProjectBusinessRulesData,
      QueryString: 'ProjectID=' + request.projectId,
      Data: ""
    }
  );
}

async function GetFinancialsAbatementData({ request }) {
  return await post(
    // `${DECARB_LIBRARY_URL.GetFinancialsAbatementData}`,request
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "DecarbLibrary",
      ActionName: DECARB_LIBRARY_URL.GetFinancialsAbatementData,
      QueryString: "",
      Data: request
    }
  );
}

async function AddProjecttoProjectLibrary({ request }) {
  return await post(
    // `${DECARB_LIBRARY_URL.AddProjecttoProjectLibrary}?ProjectID=${request.ProjectID}&EngagementCode=${request.EngagementCode}`,request
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "DecarbLibrary",
      ActionName: DECARB_LIBRARY_URL.AddProjecttoProjectLibrary,
      QueryString: 'ProjectID=' + request.ProjectID + `&` + 'EngagementCode=' + request.EngagementCode,
      Data: request
    }
  );
}

async function GetGMPTableData({ request }) {
  return await post(
    // `${DECARB_LIBRARY_URL.GetGMPTableData}?EnagagementCode=${request.engagementCode}&ProjectID=${request.projectID}`,null
    `/WebApi/Invoke`, {
      ActionVerb: "POST",
      ControllerName: "DecarbLibrary",
      ActionName: DECARB_LIBRARY_URL.GetGMPTableData,
      QueryString: 'EnagagementCode=' + request.engagementCode + `&` + 'ProjectID=' + request.projectID,
      Data: ""
    }
  );
}

export {
  GetAllProjectlibraryDetails,
  GetBusinessCaseSummaryData,
  GetProjectBusinessRulesData,
  GetFinancialsAbatementData,
  AddProjecttoProjectLibrary,
  GetGMPTableData,
};
