import React, { useEffect, useState } from "react";
import "./SideMenu.scss";
import {
  MotifVerticalNavigationContent,
  MotifVerticalNavigationMenu,
  MotifVerticalNavigationMenuItem,
  MotifVerticalNavigation,
  MotifVerticalNavigationFooter,
} from "@ey-xd/motif-react";
import { BookIcon, FolderIcon, HomeIcon, PrivacyIcon } from "../../icons";
import { routes } from "../../routes/routes.constatns";
import { releaseAlllocks } from "../../store/services/engagement.service";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserConsent,
  setDefaultLoader,
} from "../../store/slices/engagementSlice";
import privacyNotice from "../../assets/templates/privacyNotice.pdf";
import { consentPrivacyText } from "../../util/constants";
export function SideMenu({ isSideMenuCollapsed }) {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [showDecarbLibrary, setShowDecarbLibrary] = useState(true);

  /* istanbul ignore next */
  async function handleSideMenuClick(route) {
    dispatch(setDefaultLoader({ loader: true }));
    await releaseAlllocks({ request: { email: user.username } });
    dispatch(setDefaultLoader({ loader: false }));
    window.location.href = `${process.env.REACT_APP_URL_REG_ALL}${route}`;
  }

  useEffect(() => {
    if (user?.username)
      dispatch(getUserConsent({ email: user.username })).then((data) => {
        let shouldShowLibrary = data?.payload?.externalUser;
        setShowDecarbLibrary(shouldShowLibrary);
      });
  }, [dispatch, user?.username]);

  return (
    <MotifVerticalNavigation
      collapse={isSideMenuCollapsed}
      className="decarb-side-menu"
    >
      <MotifVerticalNavigationContent>
        <MotifVerticalNavigationMenu>
          <MotifVerticalNavigationMenuItem
            className={showDecarbLibrary ? "hide-decarbLibrary" : ""}
            icon={<HomeIcon />}
            label="Home"
            onClick={() => handleSideMenuClick(routes.home)}
          >
            Home
          </MotifVerticalNavigationMenuItem>
          <MotifVerticalNavigationMenuItem
            icon={<FolderIcon />}
            label="Engagement dashboard"
            onClick={() => handleSideMenuClick(`${routes.viewEngagement}`)}
          >
            Engagement dashboard
          </MotifVerticalNavigationMenuItem>

          <MotifVerticalNavigationMenuItem
            className={showDecarbLibrary ? "hide-decarbLibrary" : ""}
            icon={<BookIcon />}
            label="Decarbonization library"
            onClick={() =>
              window.open(
                window.location.origin + `/${routes.decarbProjectsLibrary}`,
                "mywindow",
                "location=0,menubar=0,status=0,scrollbars=0,width=900,height=400"
              )
            }
          >
            Decarbonization library
          </MotifVerticalNavigationMenuItem>
        </MotifVerticalNavigationMenu>
      </MotifVerticalNavigationContent>
      <MotifVerticalNavigationFooter>
        <MotifVerticalNavigationMenu>
          <MotifVerticalNavigationMenuItem
            label={"privacyNotice"}
            icon={<PrivacyIcon />}
          >
            <a className="content-notice" href={privacyNotice} download>
              {consentPrivacyText}
            </a>
          </MotifVerticalNavigationMenuItem>
        </MotifVerticalNavigationMenu>
      </MotifVerticalNavigationFooter>
    </MotifVerticalNavigation>
  );
}
