import { POWER_BI_URL } from "./endpoints/powerBI";
import axios from "axios";

async function getPowerBIEmbedToken({ request }) {
  const instance = axios.create();

  return await instance.post(
    // `${POWER_BI_URL.GetEmbedToken}?userName=${request.username}&datasetId=${request.datasetId}`,
    `/WebApi/Invoke`,
    {
      ActionVerb: "GET",
      ControllerName: "Engagement",
      ActionName: "PowerBI/GetEmbedToken",
      QueryString:
        "userName=" + request.username + "&" + "datasetId=" + request.datasetId,
      Data: "",
    },
    {
      withCredentials: true,
      baseURL: request.baseUrl,
      headers: {
        Authorization: `Bearer ${request.accessToken}`,
      },
    }
  );
}

export { getPowerBIEmbedToken };
