import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import HighchartsTreemap from "highcharts/modules/treemap";
import Highcharts from "highcharts";
import { Button } from "../../Button/Button";
import {
  changeLog,
  emissionGraphHeading,
  reduceChartNoData,
} from "../../../util/constants";
import { useSelector } from "react-redux";
import { Log } from "../../../icons";
import { ChangeLog } from "./EmissionChangeLog";
import "../Emissions.scss";
import { LoadingIndicator } from "../../LoadingIndicator/LoadingIndicator";

export function EmissionGraph({ loader }) {
  const reduceDefaultViewData = useSelector(
    (state) => state.project.defaultViewData
  );
  const isGraphSimulate = useSelector((state) => state.project.isGraphSimulate);
  const simulateGraphData = useSelector(
    (state) => state.project.simulateGraphData
  );

  HighchartsTreemap(Highcharts);
  const [chartOptions, setChartOptions] = useState({});
  const [changeLogModalOpen, setChangeLogModalOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const isFilterApplied = useSelector(
    (state) => state.project.emissionFiltersApplied
  );
  useEffect(() => {
    if (reduceDefaultViewData.graphData && !isGraphSimulate) {
      updateChartDataSet(reduceDefaultViewData.graphData);
    }
    if (isGraphSimulate) {
      updateChartDataSet(simulateGraphData);
    }
  }, [reduceDefaultViewData, isGraphSimulate]);

  useEffect(() => {
    if (loader) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [loader]);

  const updateChartDataSet = (data) => {
    let graphDataArray = [];
    data?.map((item, i) => {
      graphDataArray.push({
        name: item.name?.split("[")[0]?.trimEnd(),
        value: item.value,
        // colorValue: i,
      });
    });
    setChartOptions({
      // colorAxis: {
      //   minColor: "#FFFFFF",
      //   maxColor: Highcharts.getOptions().colors[0],
      // },
      chart: {
        height: 600, // Set the desired height in pixels
      },
      plotOptions: {
        series: {
          borderWidth: 0,
        },
      },
      series: [
        {
          type: "treemap",
          layoutAlgorithm: "squarified",
          layoutStartingDirection: "horizantal",
          clip: false,
          data: graphDataArray,
          colorByPoint: true,
          colors: [
            "#108FE1",
            "#1E90FF",
            "#0D76CB",
            "#4084D4",
            "#87CEFA",
            "#5BAAF7",
            "#0B6CC8",
            "#1370B2",
            "#1B94E5",
            "#81B7FC",
            "#105484",
            "#00BFFF",
            "#0971C7",
            "#80B1F9",
            "#097BCA",
            "#0A6EAF",
            "#0000CD",
            "#07578E",
            "#599AE0",
            "#52A7FF",
          ],
        },
      ],
      title: {
        text: "",
      },
    });
  };
  return (
    <div className="emissions-graph-section">
      <div className="emissions-graph-title">
        <span>{emissionGraphHeading}</span>
        <Button
          onClick={() => setChangeLogModalOpen(true)}
          variant="text"
          className={"manage-emissions-body-upload-file-history-button"}
          disabled={!isFilterApplied}
          data-testid="changeLog-button"
        >
          <Log />
          <span className="emissions-graph-title-log">{changeLog}</span>
        </Button>
      </div>
      <div className="emissions-graph-content" data-testid="loading-indicator">
        {/* {reduceDefaultViewData && reduceDefaultViewData.graphData.length > 0 ? 
      <span className="emissions-graph-content-noData">{reduceChartNoData}</span>
      : ""
      } */}
        <LoadingIndicator show={showLoader} transparent fullscreen={false}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </LoadingIndicator>
      </div>
      {/* ChangeLog modal */}
      <ChangeLog
        isChangeLogOpen={changeLogModalOpen}
        onClose={() => setChangeLogModalOpen(false)}
      />
    </div>
  );
}
