import React, { useState, useEffect } from "react";
import {
  SaveBtn,
  addForecastSpecificationDataType,
  addForecastSpecificationDataTypeOptions,
  cumulative,
  endYr,
  simulate,
  strtYr,
  unit,
  unitDropdown,
  unitTooltip,
  simulateValue,
  yesNo,
  addForecastSpecificationStartYearGreaterThanMRY,
  addForecastSpecificationStartYearLessThanFEY,
  endYearGreaterThanFEYear,
  endYearLessThanStartYear,
  ruleName,
  decimalsNotAllowedError,
  relativePercentageError,
  only2DecimalsAllowed,
  negativeNotallowed,
  emissionArray,
  name,
  targetHierarchy,
  emisFactorUnit,
  actDataUnit,
  emisFactValue,
  actDataValue,
  transferAd,
  targetHierarchyValues,
  maxlimitExceeded,
  onlyNumbersAllowed,
  ruleNameTooltip,
  reduceDataTypetooltip,
  replaceDataTypeTooltip,
  compensateDataTypeTooltip,
  cumulativeTooltip,
  emissionsValuetooltip,
  emissionsStartyearTooltip,
  emissionsEndYeartooltip,
  targetHierarchyTooltip,
  activityDataValueTooltip,
  emissionFactorTooltip,
  transferADTooltip,
  activityDataRelativeError,
  ruleDescription,
  addGMPSource,
  edittype,
  editTypeOptions,
  absoluteorRelative,
  projectDetailsBusinessRulesChangeType,
  editExtend,
  cumulativedropdown,
} from "../../../util/constants";
import { Select } from "../../Select/Select";
import {
  AreaSeacrhIcon,
  EmissionIcon,
  SaveIcon,
  SimulateIcon,
} from "../../../icons";
import { Button } from "../../Button/Button";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
import { Input } from "../../Input/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  setSimulateDataOnTable,
  setSimulateFlag,
  setGraphSimulateFlag,
  setSimulateDataOnGraph,
  setReduceDefautViewData,
  setIsAnyRowSelected,
  setEditDataButton,
  getLockingData,
  setTogglechecked,
  resetManualEditRules,
  setSelectedRowData,
  setM3Step2Loader,
  setIsManualEdit,
} from "../../../store/slices/projectSlice";
import {
  getColumnValueFlags,
  getM3TableGraphData,
  manualEditM3Step2,
  SaveM3Step2ReplaceData,
} from "../../../store/services/project.service";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";
import { TextArea } from "../../TextArea/TextArea";
import { EmissionFilterModal } from "../EmissionsFilterModal/EmissionFilterModal";
import "../Emissions.scss";

export function SimulateFields() {
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const user = useSelector((state) => state.user.user);
  const filtersPayload = useSelector(
    (state) => state.project.appliedFilterPayload
  );
  const reduceDefaultViewData = useSelector(
    (state) => state.project.defaultViewData
  );
  const isResetApplied = useSelector((state) => state.project.isResetApplied);
  const [dataType, setDataType] = useState("");
  const [cumlative, setCumulative] = useState("");
  const [unitSelected, setUnit] = useState("");
  const [value, setValue] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [valueErrorMessage, setValueErrorMessage] = useState("");
  const [valueError, setValueError] = useState(false);
  const [startYearErrorMessage, setStartYearErrorMessage] = useState("");
  const [endYearErrorMessage, setEndYearErrorMessage] = useState("");
  const decimalNumberRegExp = /^-?\d{1,13}(\.?\d{1,2})?$/;
  const onlyNumbersRegExp = /^-?\d{1,11}$/;
  const onlyPositiveRegExp = /^\d{1,15}$/;
  const [showstartYearError, setShowStartYearError] = useState(false);
  const [showendYearError, setShowEndYearError] = useState(false);
  const [rule, setRuleName] = useState("");
  const [ruleDesc, setRuleDesc] = useState("");
  const [editType, setEditType] = useState("Batch edit");
  const [isSimulateApplied, setSimulateApplied] = useState(false);
  const [isSimulateBtnDisable, setSimulateBtnDisable] = useState(true);
  const [simulateLoader, setSimulateloader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [extendCumulative, setExtendCumulative] = useState("");
  const [trgtHrchy, setTrgtHrchy] = useState("");
  const [replaceName, setReplaceName] = useState("");
  const [activityDataValue, setActivityDataValue] = useState("");
  const [emissionFactorValue, setEmissionFactorValue] = useState("");
  const [activityDataUnit, setActivityDataUnit] = useState("");
  const [emissionFactorUnit, setEmissionFactorUnit] = useState("");
  const [trnsfrAD, setTransferAD] = useState("");
  const [activityDataValueErrorMessage, setActivityDataValueErrorMessage] =
    useState("");
  const [isActivityDataValueError, setisActivityDataValueError] =
    useState(false);
  const [emissionFactorValueErrorMessage, setEmissionFactorValueErrorMessage] =
    useState("");
  const [isEmissionFactorValueError, setIsEmissionFactorValueError] =
    useState(false);
  const [replaceChange, setReplaceChangeType] = useState("Edit");
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [extendError, setExtendError] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [source, setSource] = useState("");

  useEffect(() => {
    checkSimulateEnableValidation();
  }, [dataType, cumlative, unitSelected, value, startYear, endYear, rule]);
  // Validation for Value field
  const checkValueValidation = (value) => {
    if (
      (dataType === "Activity data" || dataType === "Emissions") &&
      unitSelected === "Absolute"
    ) {
      if (value === "-") {
        setValueError(false);
        setValueErrorMessage("");
      } else if (!onlyNumbersRegExp.test(value)) {
        setValueErrorMessage(decimalsNotAllowedError);
        setValueError(true);
      } else {
        setValueError(false);
        setValueErrorMessage("");
      }
    }
    if (
      (dataType === "Activity data" ||
        dataType === "Emission factor" ||
        dataType === "Emissions") &&
      unitSelected === "Relative"
    ) {
      if (value === "" || value === "-") {
        setValueError(false);
        setValueErrorMessage("");
      } else if (value < -100 || value > 9999.99) {
        setValueErrorMessage(relativePercentageError);
        setValueError(true);
      } else if (!decimalNumberRegExp.test(value)) {
        setValueError(true);
        setValueErrorMessage(only2DecimalsAllowed);
      } else {
        setValueError(false);
        setValueErrorMessage("");
      }
    }
    if (dataType === "Emission factor" && unitSelected === "Absolute") {
      setValueError(false);
      setValueErrorMessage("");
      if (value === "" || value === "-") {
        setValueError(false);
        setValueErrorMessage("");
      } else if (value < 0) {
        setValueErrorMessage(negativeNotallowed);
        setValueError(true);
      } else if (!decimalNumberRegExp.test(value)) {
        if (!decimalNumberRegExp.test(value)) {
          setValueErrorMessage(only2DecimalsAllowed);
          setValueError(true);
        } else if (!onlyNumbersRegExp.test(value)) {
          setValueErrorMessage(onlyNumbersAllowed);
          setValueError(true);
        }
      } else if (parseFloat(value) > 99999999999) {
        setValueErrorMessage(maxlimitExceeded);
        setValueError(true);
      } else {
        setValueError(false);
        setValueErrorMessage("");
      }
    }
  };

  // Start year validation check
  const checkStartYearValidation = (value) => {
    const mostRecentYear = engagement.mostRecentYear;
    if (value && parseFloat(value) <= mostRecentYear) {
      setStartYearErrorMessage(addForecastSpecificationStartYearGreaterThanMRY);
      setShowStartYearError(true);
    } else if (value && parseFloat(value) > engagement?.foreCastEndYear) {
      setStartYearErrorMessage(addForecastSpecificationStartYearLessThanFEY);
      setShowStartYearError(true);
    } else {
      setStartYearErrorMessage("");
      setShowStartYearError(false);
    }
  };

  // End year validation check
  const checkEndYearValidation = (value) => {
    const forecastEndYear = engagement.foreCastEndYear;
    if (value && parseFloat(value) > forecastEndYear) {
      setEndYearErrorMessage(endYearGreaterThanFEYear);
      setShowEndYearError(true);
    } else if (value && startYear && parseFloat(value) < startYear) {
      setEndYearErrorMessage(endYearLessThanStartYear);
      setShowEndYearError(true);
    } else {
      setEndYearErrorMessage("");
      setShowEndYearError(false);
    }
  };

  //  Activity data value validation for Replace Extend
  const checkActivityDataValue = (value) => {
    if (unitSelected === "Absolute") {
      if (value == "") {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(true);
      } else if (!onlyPositiveRegExp.test(value)) {
        if (value < 0 || value === "-") {
          setActivityDataValueErrorMessage(negativeNotallowed);
          setisActivityDataValueError(true);
        } else if (!onlyPositiveRegExp.test(value)) {
          setActivityDataValueErrorMessage(decimalsNotAllowedError);
          setisActivityDataValueError(true);
        }
      } else if (value > 99999999999) {
        setActivityDataValueErrorMessage(maxlimitExceeded);
        setisActivityDataValueError(true);
      } else {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(false);
      }
    } else {
      if (value === "") {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(false);
      } else if (value > 100) {
        setActivityDataValueErrorMessage(activityDataRelativeError);
        setisActivityDataValueError(true);
      } else if (!onlyPositiveRegExp.test(value)) {
        if (value > 100) {
          setActivityDataValueErrorMessage(activityDataRelativeError);
          setisActivityDataValueError(true);
        } else if (value < 0) {
          setActivityDataValueErrorMessage(negativeNotallowed);
          setisActivityDataValueError(true);
        } else if (!decimalNumberRegExp.test(value)) {
          setActivityDataValueErrorMessage(only2DecimalsAllowed);
          setisActivityDataValueError(true);
        } else {
          setActivityDataValueErrorMessage("");
          setisActivityDataValueError(false);
        }
      } else {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(false);
      }
    }
  };

  // Emission factor value validations for Replace Extend scenario
  const checkEmissionFactorValue = (value) => {
    if (unitSelected === "Absolute") {
      if (value === "") {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      } else if (value < 0 || value === "-") {
        setEmissionFactorValueErrorMessage(negativeNotallowed);
        setIsEmissionFactorValueError(true);
      } else if (value > 99999999999) {
        setEmissionFactorValueErrorMessage(maxlimitExceeded);
        setIsEmissionFactorValueError(true);
      } else if (!decimalNumberRegExp.test(value)) {
        setEmissionFactorValueErrorMessage(only2DecimalsAllowed);
        setIsEmissionFactorValueError(true);
      } else {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      }
    } else {
      if (value === "") {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      } else if (value < -100 || value > 9999.99) {
        setEmissionFactorValueErrorMessage(relativePercentageError);
        setIsEmissionFactorValueError(true);
      } else {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      }
    }
  };

  // Onchange of Input fields
  const handleOnChange = (value, fieldName) => {
    if (fieldName === "dataType") {
      setDataType(value);
      setValue("");
    } else if (fieldName === "cumulative") {
      setCumulative(value);
      setValue("");
    } else if (fieldName === "unit") {
      setUnit(value);
      setValue("");
      setActivityDataUnit("");
      setEmissionFactorUnit("");
    } else if (fieldName === "value") {
      checkValueValidation(value);
      setValue(value);
    } else if (fieldName === "startYear") {
      checkStartYearValidation(value);
      setStartYear(value);
    } else if (fieldName === "endYear") {
      checkEndYearValidation(value);
      setEndYear(value);
    } else if (fieldName === "ruleName") {
      setRuleName(value);
    } else if (fieldName === "activityDataValue") {
      checkActivityDataValue(value);
      setActivityDataValue(value);
    } else if (fieldName === "emissionFactorValue") {
      checkEmissionFactorValue(value);
      setEmissionFactorValue(value);
    } else if (fieldName === "activityDataUnit") {
      setActivityDataUnit(value);
      if (value !== "") {
        setEmissionFactorUnit("kgCO2e/" + value);
      } else {
        setEmissionFactorUnit("");
      }
    } else if (fieldName === "name") {
      setReplaceName(value);
    } else if (fieldName === "targetHierarchy") {
      setTrgtHrchy(value);
      //   checkTargetHierarchy(value); needs to be changed during extend implementation
    } else if (fieldName === "extendcumulative") {
      setExtendCumulative(value);
    } else if (fieldName === "transferAD") {
      setTransferAD(value);
    } else if (fieldName === "ruleDescription") {
      setRuleDesc(value);
    } else if (fieldName === "source") {
      setSource(value);
    } else if (fieldName === "editType") {
      setEditType(value);
      if (value === "Manual edit") {
        dispatch(setIsManualEdit({ isManualEdit: true }));
      } else {
        dispatch(setIsManualEdit({ isManualEdit: false }));
      }
    } else if (fieldName === "replaceChangeType") {
      setReplaceChangeType(value);
    }
  };

  const checkSimulateEnableValidation = () => {
    if (editType === "Batch edit") {
      if (
        dataType &&
        cumlative &&
        unitSelected &&
        value &&
        startYear &&
        endYear &&
        rule &&
        !valueError &&
        !showstartYearError &&
        !showendYearError
      ) {
        setSimulateBtnDisable(false);
      } else {
        setSimulateBtnDisable(true);
      }
    }
  };

  const openFiltersModal = () => {
    setIsFilterOpen(true);
  };

  //Closing the data selection
  const onfilterClose = () => {
    setIsFilterOpen(false);
  };

  //Simulate function
  const onSimulate = async () => {
    dispatch(setGraphSimulateFlag({ isGraphSimulate: false }));
    setSimulateloader(true);
    dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
    if (project.projectType === 2) {
      let simulateSaveReplacePayload = {
        ...simulateSavePayload,
        target_Name: "",
        view_type: "Simulate",
        ruleType: "Simulate",
        changeType: "Edit",
        activitydata_value: "",
        activitydata_Unit: "",
        emissiondata_value: "",
        emissiondata_unit: "",
        targetHierarchy: "",
        transferAD: "N",
        columnNames: filtersPayload.columnNames,
        selectedcolumns: [],
        nonselectedcolumns: [],
      };
      const data = await SaveM3Step2ReplaceData({
        simulateSaveReplacePayload,
      });
      dispatch(setSimulateDataOnTable({ simulateTableData: data.tableData }));
      dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
      dispatch(setSimulateDataOnGraph({ simulateGraphData: data.graphData }));
    } else {
      let request = filtersPayload;
      simulateSavePayload["view_type"] = "Simulate";
      simulateSavePayload["columnNames"] = request.columnNames;
      const data = await getM3TableGraphData({ simulateSavePayload });
      dispatch(setSimulateDataOnTable({ simulateTableData: data.tableData }));
      dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
      dispatch(setSimulateDataOnGraph({ simulateGraphData: data.graphData }));
    }
    dispatch(setSimulateFlag({ isSimulate: true }));
    setSimulateApplied(true);
    setSimulateloader(false);
    dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
  };

  //Save function
  const onSave = async () => {
    try {
      setSaveLoader(true);
      dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
      setSimulateloader(false);
      if (project.projectType === 2) {
        let simulateSaveReplacePayload = {
          ...simulateSavePayload,
          target_Name: "",
          view_type: "M3-SaveRule",
          changeType: "Edit",
          ruleType: "Save",
          activitydata_value: "",
          activitydata_Unit: "",
          emissiondata_value: "",
          emissiondata_unit: "",
          targetHierarchy: "",
          transferAD: "N",
          columnNames: filtersPayload.columnNames,
          selectedcolumns: [],
          nonselectedcolumns: [],
        };
        const data = await SaveM3Step2ReplaceData({
          simulateSaveReplacePayload,
        });
        dispatch(setReduceDefautViewData({ defaultViewData: data }));
      } else {
        let request = filtersPayload;
        simulateSavePayload["view_type"] = "M3-SaveRule";
        simulateSavePayload["columnNames"] = request.columnNames;
        const data = await getM3TableGraphData({ simulateSavePayload });
        dispatch(setReduceDefautViewData({ defaultViewData: data }));
      }
      setSaveLoader(false);
      dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
      setSimulateloader(false);
      setSimulateApplied(false);
      setSimulateBtnDisable(true);
    } catch (error) {
      setSaveLoader(false);
      dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
      setSimulateloader(false);
      setSimulateApplied(false);
      setSimulateBtnDisable(true);
    }
  };

  let simulateSavePayload = {
    engagementCode: engagement.engagementCode,
    engagementId: engagement.id,
    name: rule,
    Ruledescription: ruleDesc,
    Source: source,
    calculation_type: unitSelected,
    cumulative_flag: cumlative === "Cumulative" ? "Y" : "N",
    abatement_value: parseFloat(value),
    data_type: dataType,
    start_year: parseInt(startYear),
    end_year: parseInt(endYear),
    createdby: user.username,
    modifiedby: user.username,
    datatype_unit: "kWh",
    project_type:
      project.projectType === 1
        ? "Reduce"
        : project.projectType === 2
        ? "Replace"
        : "Compensate",
    project_id: project.projectId,
    dropDownMetaData: [
      {
        columnType: "Scope",
        columnValue: "All",
      },
      {
        columnType: "Category_level1",
        columnValue: "All",
      },
      {
        columnType: "Category_level2",
        columnValue: "All",
      },
      {
        columnType: "Category_level3",
        columnValue: "All",
      },
      {
        columnType: "Region_level1",
        columnValue: "All",
      },
      {
        columnType: "Region_level2",
        columnValue: "All",
      },
      {
        columnType: "Region_level3",
        columnValue: "All",
      },
      {
        columnType: "Entity_level1",
        columnValue: "All",
      },
      {
        columnType: "Entity_level2",
        columnValue: "All",
      },
      {
        columnType: "Entity_level3",
        columnValue: "All",
      },
      {
        columnType: "Entity_level4",
        columnValue: "All",
      },
      {
        columnType: "Custom_specification1",
        columnValue: "All",
      },
      {
        columnType: "Custom_specification2",
        columnValue: "All",
      },
      {
        columnType: "Custom_specification3",
        columnValue: "All",
      },
    ],
  };
  return (
    <div className="emissions-simulate-section">
      <div className="emissions-simulate-content">
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {ruleName}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={ruleNameTooltip}
              ></Tooltip>
            </span>
            <Input
              type="text"
              onChange={(event) => {
                handleOnChange(event?.target?.value, "ruleName");
              }}
              value={rule}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
              maxLength={50}
            ></Input>
          </div>
        </div>
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {ruleDescription}
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={""}
              ></Tooltip>
            </span>
            <TextArea
              type="text"
              onChange={(event) => {
                handleOnChange(event?.target?.value, "ruleDescription");
              }}
              value={ruleDesc}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
              maxLength={5000}
            ></TextArea>
          </div>
        </div>
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {addGMPSource}
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={""}
              ></Tooltip>
            </span>
            <Input
              type="text"
              onChange={(event) => {
                handleOnChange(event?.target?.value, "source");
              }}
              value={source}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
              maxLength={5000}
            ></Input>
          </div>
        </div>
        {project.projectType === 2 && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {projectDetailsBusinessRulesChangeType}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={ruleNameTooltip}
                ></Tooltip>
              </span>
              <Select
                options={editExtend}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "replaceChangeType");
                }}
                value={replaceChange}
                disabled={
                  lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId
                }
              ></Select>
            </div>
          </div>
        )}
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {edittype}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={""}
              ></Tooltip>
            </span>
            <Select
              options={editTypeOptions}
              onChange={(event) => {
                handleOnChange(event, "editType");
              }}
              value={editType}
            ></Select>
          </div>
        </div>
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <Button variant="primary-alt" onClick={() => openFiltersModal()}>
              <AreaSeacrhIcon />
              {"Data selection"}
            </Button>
          </div>
        </div>
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {addForecastSpecificationDataType}{" "}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={
                  project.projectType === 1
                    ? reduceDataTypetooltip
                    : project.projectType === 2
                    ? replaceDataTypeTooltip
                    : compensateDataTypeTooltip
                }
              ></Tooltip>
            </span>
            <Select
              options={
                project.projectType === 3
                  ? emissionArray
                  : addForecastSpecificationDataTypeOptions
              }
              value={dataType}
              onChange={(event) => {
                handleOnChange(event, "dataType");
              }}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
            ></Select>
          </div>
        </div>
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {absoluteorRelative}
              <span className="tooltip">
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={unitTooltip}
                ></Tooltip>
              </span>
              <span className="required-star">*</span>
            </span>
            <Select
              options={unitDropdown}
              value={unitSelected}
              onChange={(event) => {
                handleOnChange(event, "unit");
              }}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
            ></Select>
          </div>
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {cumulative}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={cumulativeTooltip}
              ></Tooltip>
            </span>
            <Select
              options={cumulativedropdown}
              value={cumlative}
              onChange={(event) => {
                handleOnChange(event, "cumulative");
              }}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
            ></Select>
          </div>
        </div>
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {strtYr}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={emissionsStartyearTooltip}
              ></Tooltip>
            </span>
            <Input
              errorMessage={startYearErrorMessage}
              isError={showstartYearError}
              disableErrorPlaceholder={true}
              onChange={(event) => {
                handleOnChange(event?.target?.value, "startYear");
              }}
              value={startYear}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
              type="number"
            />
          </div>
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {endYr}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={emissionsEndYeartooltip}
              ></Tooltip>
            </span>
            <Input
              errorMessage={endYearErrorMessage}
              isError={showendYearError}
              disableErrorPlaceholder={true}
              onChange={(event) => {
                handleOnChange(event?.target?.value, "endYear");
              }}
              value={endYear}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
              type="number"
            />
          </div>
        </div>
        <div className="emissions-simulate-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {simulateValue}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={emissionsValuetooltip}
              ></Tooltip>
            </span>
            <Input
              errorMessage={valueErrorMessage}
              isError={valueError}
              disableErrorPlaceholder={true}
              onChange={(event) => {
                handleOnChange(event?.target?.value, "value");
              }}
              maxLength={value < 0 ? 12 : 11}
              disabled={
                unitSelected === "" ||
                dataType === "" ||
                (lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId)
              }
              value={value}
              type={"decimal"}
            />
          </div>
          <div className="emissions-simulate-content-row-dropdown"></div>
        </div>
      </div>
      <div className="emissions-simulate-footer">
        <LoadingIndicatorEmbed show={simulateLoader}>
          <Button
            variant="primary-alt"
            onClick={onSimulate}
            disabled={
              isSimulateBtnDisable ||
              (lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId)
            }
          >
            <SimulateIcon /> {simulate}
          </Button>
        </LoadingIndicatorEmbed>

        <LoadingIndicatorEmbed show={saveLoader}>
          <Button
            variant="primary-alt"
            onClick={() => onSave("Save")}
            disabled={
              isSimulateBtnDisable ||
              (lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId)
            }
          >
            <SaveIcon />
            {SaveBtn}
          </Button>
        </LoadingIndicatorEmbed>
      </div>
      <EmissionFilterModal
        isFilterOpen={isFilterOpen}
        onClose={onfilterClose}
      />
    </div>
  );
}
