import React from "react";

export function AreaSeacrhIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 15.75C16.7574 15.75 15.75 16.7574 15.75 18C15.75 19.2426 16.7574 20.25 18 20.25C18.6226 20.25 19.1849 19.9981 19.5931 19.5889C19.9998 19.1811 20.25 18.6205 20.25 18C20.25 16.7574 19.2426 15.75 18 15.75ZM14.25 18C14.25 15.9289 15.9289 14.25 18 14.25C20.0711 14.25 21.75 15.9289 21.75 18C21.75 18.7629 21.5217 19.4732 21.1303 20.0654L22.5311 21.4705C22.8236 21.7638 22.8229 22.2387 22.5295 22.5311C22.2362 22.8236 21.7613 22.8229 21.4689 22.5295L20.0705 21.1269C19.4773 21.5203 18.7651 21.75 18 21.75C15.9289 21.75 14.25 20.0711 14.25 18Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 2C3.25 1.58579 3.58579 1.25 4 1.25H7C7.41421 1.25 7.75 1.58579 7.75 2C7.75 2.41421 7.41421 2.75 7 2.75H4.75V5C4.75 5.41421 4.41421 5.75 4 5.75C3.58579 5.75 3.25 5.41421 3.25 5V2Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10.25C4.41421 10.25 4.75 10.5858 4.75 11V13C4.75 13.4142 4.41421 13.75 4 13.75C3.58579 13.75 3.25 13.4142 3.25 13V11C3.25 10.5858 3.58579 10.25 4 10.25Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 2C10.25 1.58579 10.5858 1.25 11 1.25H13C13.4142 1.25 13.75 1.58579 13.75 2C13.75 2.41421 13.4142 2.75 13 2.75H11C10.5858 2.75 10.25 2.41421 10.25 2Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 22C10.25 21.5858 10.5858 21.25 11 21.25H13C13.4142 21.25 13.75 21.5858 13.75 22C13.75 22.4142 13.4142 22.75 13 22.75H11C10.5858 22.75 10.25 22.4142 10.25 22Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10.25C20.4142 10.25 20.75 10.5858 20.75 11V13C20.75 13.4142 20.4142 13.75 20 13.75C19.5858 13.75 19.25 13.4142 19.25 13V11C19.25 10.5858 19.5858 10.25 20 10.25Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 2C20.75 1.58579 20.4142 1.25 20 1.25H17C16.5858 1.25 16.25 1.58579 16.25 2C16.25 2.41421 16.5858 2.75 17 2.75H19.25V5C19.25 5.41421 19.5858 5.75 20 5.75C20.4142 5.75 20.75 5.41421 20.75 5V2Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 22C3.25 22.4142 3.58579 22.75 4 22.75H7C7.41421 22.75 7.75 22.4142 7.75 22C7.75 21.5858 7.41421 21.25 7 21.25H4.75V19C4.75 18.5858 4.41421 18.25 4 18.25C3.58579 18.25 3.25 18.5858 3.25 19V22Z"
        fill="#A4A3B1"
      />
    </svg>
  );
}
