import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";

const useRegRedirectionAuthToken = () => {
  const authConfig = {
    // clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    clientId: `8c98ae3b-bfe7-4921-99ed-5e4b530985e4`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_APPLICATION_ID}`,
    redirectUri: `${process.env.REACT_APP_URL_BASE}home`,
    navigateToLoginRequestUrl: true,
    applicationId: process.env.REACT_APP_AZURE_APPLICATION_ID,
  };
  
  const cache = {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  };
  const msalConfig = {
    auth: authConfig,
    cache: cache,
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  const { accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        // Ensure MSAL instance is initialized
        await msalInstance.initialize();

        if (inProgress === "none" && accounts.length > 0) {
          const request = {
            scopes: ["api://2bfcdd28-0445-4dd7-95ba-ce62a9383ed9/.default"],
            account: accounts[0],
          };

          const response = await msalInstance.acquireTokenSilent(request);
          setAccessToken(response.accessToken);
        }
      } catch (error) {
        console.error("Token acquisition failed:", error);
      }
    };

    initializeMsal();
  }, [inProgress, accounts]);

  return accessToken;
};

export { useRegRedirectionAuthToken };
