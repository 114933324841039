import React, { useEffect, useState } from "react";
import { Modal } from "../../Modal/Modal";
import {
  ApplyBtn,
  emissionFilterTooltip,
  filters,
  reset,
  dropdowns,
} from "../../../util/constants";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
import { EmissionIcon } from "../../../icons";
import "../Emissions.scss";
import { Select } from "../../Select/Select";
import { Button } from "../../Button/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  saveEmissionFiltersReduce,
  getReduceDefautViewData,
} from "../../../store/services/project.service";
import {
  setReduceDefautViewData,
  setselectedUIAllFilters,
  setAppliedFiltersPayload,
  setSimulateFlag,
  setGraphSimulateFlag,
  setTableFiltersApplied,
  setLastAppliedFilters,
} from "../../../store/slices/projectSlice";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";

function ModalBody({ onApply }) {
  const dispatch = useDispatch();
  //Fetching state variables
  const overallFilters = useSelector(
    (state) => state.project.selectedUIHeaderFilters
  );
  const initialDropdowns = useSelector(
    (state) => state.project.hierarchialData
  );
  const selectedUIHeaderFilters = useSelector(
    (state) => state.project.selectedUIHeaderFilters
  );
  const selectedUIAllFilters = useSelector(
    (state) => state.project.selectedUIAllFilters
  );
  const [selectedUIfilters, setSelectedUIAllFilters] =
    useState(selectedUIAllFilters);
  const [dropdownOptions, setDropdownOptions] = useState([...initialDropdowns]);
  const [frozenDataset, setFrozenDataset] = useState([...initialDropdowns]);
  const project = useSelector((state) => state.project.project);
  const engagement = useSelector((state) => state.engagement.engagement);
  const user = useSelector((state) => state.user.user);
  const [showLoader, setLoader] = useState(false);
  const filtersApplied = useSelector(
    (state) => state.project.isTableFiltersApplied
  );
  const [modalFiltersApplied, setModalFiltersApplied] = useState(false);
  const lastAppliedFilters = useSelector(
    (state) => state.project.lastAppliedFilters
  );

  // Locking the initial Dataset based on the Header Filter selection
  useEffect(() => {
    if (project?.projectId) {
      setInitialDropdowns();
    }
  }, [project?.projectId]);

  // useEffect(() => {
  //   //setModalFiltersApplied(false);
  //   dropdowns.forEach((element) => {
  //     element.dropdownList.forEach((record) => {
  //       if (
  //         record.fieldId.toLowerCase() ===
  //         lastAppliedFilters?.highestLevelFilter?.toLowerCase()
  //       ) {
  //         if (
  //           lastAppliedFilters[
  //             lastAppliedFilters.highestLevelFilter.toLowerCase()
  //           ]?.length !==
  //           selectedUIfilters[
  //             lastAppliedFilters.highestLevelFilter.toLowerCase()
  //           ]?.length
  //         ) {
  //           setModalFiltersApplied(true);
  //         } else {
  //           setModalFiltersApplied(false);
  //         }
  //       } else {
  //         if (selectedUIfilters[record.fieldId]?.length > 0) {
  //           setModalFiltersApplied(true);
  //         }
  //       }
  //     });
  //   });
  //   dispatch(setLastAppliedFilters({ lastAppliedFilters: selectedUIfilters }));
  // }, [selectedUIfilters, selectedUIHeaderFilters]);

  // Populating last applied filters logic
  useEffect(() => {
    dispatch(
      setLastAppliedFilters({ lastAppliedFilters: selectedUIHeaderFilters })
    );
  }, [selectedUIHeaderFilters]);

  const setInitialDropdowns = async () => {
    // const dropdownfilter = selectedUIHeaderFilters.highestLevelFilter;
    // let array = initialDropdowns.filter((element) =>
    //   selectedUIHeaderFilters[dropdownfilter]?.includes(element[dropdownfilter])
    // );
    setFrozenDataset(initialDropdowns);
  };

  //Disabling dropdowns based on header filters selection
  const disableDropdowns = (label) => {
    switch (label) {
      case "Scope":
        // if (overallFilters.highestLevelFilter.toLowerCase() !== "scope") {
        //   return true;
        // } else {
        //   return false;
        // }
        return false;
      case "Subcategory 1":
        if (
          // overallFilters?.category_level1?.length > 0 &&
          // overallFilters.highestLevelFilter !== "category_level1"
          selectedUIfilters.scope?.length === 0 ||
          !selectedUIfilters.scope
        ) {
          return true;
        }
        break;
      case "Subcategory 2":
        if (
          selectedUIfilters.category_level1?.length === 0 ||
          !selectedUIfilters.category_level1
        ) {
          return true;
        }
        break;
      case "Subcategory 3":
        if (
          selectedUIfilters.category_level2?.length === 0 ||
          !selectedUIfilters.category_level2
        ) {
          return true;
        }
        break;
      case "Region level 1":
        if (selectedUIfilters.scope?.length === 0 || !selectedUIfilters.scope) {
          return true;
        }
        break;
      case "Region level 2":
        if (
          selectedUIfilters.region_level1?.length === 0 ||
          !selectedUIfilters.region_level1
        ) {
          return true;
        }
        break;
      case "Region level 3":
        if (
          selectedUIfilters.region_level2?.length === 0 ||
          !selectedUIfilters.region_level2
        ) {
          return true;
        }
        break;
      case "Entity level 1":
        if (selectedUIfilters.scope?.length === 0 || !selectedUIfilters.scope) {
          return true;
        }
        break;
      case "Entity level 2":
        if (
          selectedUIfilters.entity_level1?.length === 0 ||
          !selectedUIfilters.entity_level1
        ) {
          return true;
        }
        break;
      case "Entity level 3":
        if (
          selectedUIfilters.entity_level2?.length === 0 ||
          !selectedUIfilters.entity_level2
        ) {
          return true;
        }
        break;
      case "Entity level 4":
        if (
          selectedUIfilters.entity_level3?.length === 0 ||
          !selectedUIfilters.entity_level3
        ) {
          return true;
        }
        break;
      case "Custom level 1":
        if (selectedUIfilters.scope?.length === 0 || !selectedUIfilters.scope) {
          return true;
        }
        break;
      case "Custom level 2":
        if (
          selectedUIfilters.custom_specification1?.length === 0 ||
          !selectedUIfilters.custom_specification1
        ) {
          return true;
        }
        break;
      case "Custom level 3":
        if (
          selectedUIfilters.custom_specification2?.length === 0 ||
          !selectedUIfilters.custom_specification2
        ) {
          return true;
        }
        break;
      default:
        return false;
    }
  };

  // Handling dropdown change

  const handleDropdownChange = async (event, fieldId) => {
    switch (fieldId) {
      case "scope":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          scope: event,
          category_level1: [],
          category_level2: [],
          category_level3: [],
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "category_level1":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          category_level1: event,
          category_level2: [],
          category_level3: [],
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "category_level2":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          category_level2: event,
          category_level3: [],
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "category_level3":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          category_level3: event,
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "region_level1":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          region_level1: event,
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "region_level2":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          region_level2: event,
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "region_level3":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          region_level3: event,
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "entity_level1":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          entity_level1: event,
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "entity_level2":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          entity_level2: event,
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "entity_level3":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          entity_level3: event,
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "entity_level4":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          entity_level4: event,
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "custom_specification1":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          custom_specification1: event,
          custom_specification2: [],
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "custom_specification2":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          custom_specification2: event,
          custom_specification3: [],
          latest_filter: fieldId,
        });
        break;
      case "custom_specification3":
        setSelectedUIAllFilters({
          ...selectedUIfilters,
          custom_specification3: event,
          latest_filter: fieldId,
        });
        break;
      default:
        break;
    }
    setModalFiltersApplied(true);
  };

  // Populating the dropdown values
  const getDropdownValues = (fieldId) => {
    let highestEmissionFilter =
      selectedUIfilters.highestLevelFilter.toLowerCase();
    let dataset = [...dropdownOptions];
    if (fieldId === "scope") {
      dataset = dataset;
    }
    if (fieldId === "category_level1") {
      if (highestEmissionFilter === "category_level1") {
        dataset = dataset;
      } else {
        const x = dropdownOptions.filter((element) =>
          selectedUIfilters["scope"]?.includes(element["scope"])
        );
        dataset = [...x];
      }
    }
    if (fieldId === "category_level2") {
      if (highestEmissionFilter === "category_level2") {
        dataset = dataset;
      } else if (selectedUIfilters.category_level1?.length > 0) {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters["category_level1"]?.includes(
            element["category_level1"]
          )
        );
      } else {
        const x = dropdownOptions.filter((element) =>
          selectedUIfilters["scope"]?.includes(element["scope"])
        );
        dataset = [...x];
      }
    }
    if (fieldId === "category_level3") {
      if (highestEmissionFilter === "category_level3") {
        dataset = dataset;
      } else {
        if (selectedUIfilters["category_level2"]?.length > 0) {
          dataset = dropdownOptions.filter((element) =>
            selectedUIfilters["category_level2"]?.includes(
              element["category_level2"]
            )
          );
        } else {
          dataset = dropdownOptions.filter((element) =>
            selectedUIfilters[highestEmissionFilter]?.includes(
              element[highestEmissionFilter]
            )
          );
        }
      }
    }
    if (fieldId === "region_level1") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters[highestEmissionFilter]?.includes(
          element[highestEmissionFilter]
        )
      );
    }
    if (fieldId === "region_level2") {
      if (selectedUIfilters["region_level1"]?.length > 0) {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters["region_level1"]?.includes(element["region_level1"])
        );
      } else {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters[highestEmissionFilter]?.includes(
            element[highestEmissionFilter]
          )
        );
      }
    }
    if (fieldId === "region_level3") {
      if (selectedUIfilters["region_level2"]?.length > 0) {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters["region_level2"]?.includes(element["region_level2"])
        );
      } else {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters[highestEmissionFilter]?.includes(
            element[highestEmissionFilter]
          )
        );
      }
    }

    if (fieldId === "entity_level1") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters[highestEmissionFilter]?.includes(
          element[highestEmissionFilter]
        )
      );
    }

    if (fieldId === "entity_level2") {
      if (selectedUIfilters["entity_level1"]?.length > 0) {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters["entity_level1"]?.includes(element["entity_level1"])
        );
      } else {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters[highestEmissionFilter]?.includes(
            element[highestEmissionFilter]
          )
        );
      }
    }
    if (fieldId === "entity_level3") {
      if (selectedUIfilters["entity_level2"]?.length > 0) {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters["entity_level2"]?.includes(element["entity_level2"])
        );
      } else {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters[highestEmissionFilter]?.includes(
            element[highestEmissionFilter]
          )
        );
      }
    }
    if (fieldId === "entity_level4") {
      if (selectedUIfilters["entity_level3"]?.length > 0) {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters["entity_level3"]?.includes(element["entity_level3"])
        );
      } else {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters[highestEmissionFilter]?.includes(
            element[highestEmissionFilter]
          )
        );
      }
    }

    if (fieldId === "custom_specification1") {
      dataset = dropdownOptions.filter((element) =>
        selectedUIfilters[highestEmissionFilter]?.includes(
          element[highestEmissionFilter]
        )
      );
    }
    if (fieldId === "custom_specification2") {
      if (selectedUIfilters["custom_specification1"]?.length > 0) {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters["custom_specification1"]?.includes(
            element["custom_specification1"]
          )
        );
      } else {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters[highestEmissionFilter]?.includes(
            element[highestEmissionFilter]
          )
        );
      }
    }
    if (fieldId === "custom_specification3") {
      if (selectedUIfilters["custom_specification2"]?.length > 0) {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters["custom_specification2"]?.includes(
            element["custom_specification2"]
          )
        );
      } else {
        dataset = dropdownOptions.filter((element) =>
          selectedUIfilters[highestEmissionFilter]?.includes(
            element[highestEmissionFilter]
          )
        );
      }
    }

    let array = [...new Set(dataset.map((element) => element[fieldId]))];
    return array;
  };

  //Resetting the dropdowns
  const resetDropdowns = async () => {
    setDropdownOptions(frozenDataset);
    setSelectedUIAllFilters(selectedUIHeaderFilters);
    dispatch(
      setselectedUIAllFilters({ selectedUIAllFilters: selectedUIHeaderFilters })
    );
    dispatch(setTableFiltersApplied({ isApplied: false }));
    setModalFiltersApplied(false);
    await applyFilters(selectedUIHeaderFilters, "Reset");
  };

  //Applying the filters

  const applyFilters = async (selectedUIfilters, action) => {
    setLoader(true);
    const filters = {
      scope: selectedUIfilters["scope"],
      category_level1:
        selectedUIfilters["category_level1"]?.length > 0
          ? selectedUIfilters["category_level1"]
          : ["All"],
      category_level2:
        selectedUIfilters["category_level2"]?.length > 0
          ? selectedUIfilters["category_level2"]
          : ["All"],
      category_level3:
        selectedUIfilters["category_level3"]?.length > 0
          ? selectedUIfilters["category_level3"]
          : ["All"],
      region_level1:
        selectedUIfilters["region_level1"]?.length > 0
          ? selectedUIfilters["region_level1"]
          : ["All"],
      region_level2:
        selectedUIfilters["region_level2"]?.length > 0
          ? selectedUIfilters["region_level2"]
          : ["All"],
      region_level3:
        selectedUIfilters["region_level3"]?.length > 0
          ? selectedUIfilters["region_level3"]
          : ["All"],
      entity_level1:
        selectedUIfilters["entity_level1"]?.length > 0
          ? selectedUIfilters["entity_level1"]
          : ["All"],
      entity_level2:
        selectedUIfilters["entity_level2"]?.length > 0
          ? selectedUIfilters["entity_level2"]
          : ["All"],
      entity_level3:
        selectedUIfilters["entity_level3"]?.length > 0
          ? selectedUIfilters["entity_level3"]
          : ["All"],
      entity_level4:
        selectedUIfilters["entity_level4"]?.length > 0
          ? selectedUIfilters["entity_level4"]
          : ["All"],
      custom_specification1:
        selectedUIfilters["custom_specification1"]?.length > 0
          ? selectedUIfilters["custom_specification1"]
          : ["All"],
      custom_specification2:
        selectedUIfilters["custom_specification2"]?.length > 0
          ? selectedUIfilters["custom_specification2"]
          : ["All"],
      custom_specification3:
        selectedUIfilters["custom_specification3"]?.length > 0
          ? selectedUIfilters["custom_specification3"]
          : ["All"],
    };
    const payload = await constructPayload(filters);
    const apiResponse = await saveEmissionFiltersReduce({ payload });
    const emessionFilterCounter = apiResponse?.[0]?.counter;
    const selectedFilters = ["scope"];
    selectedUIfilters["category_level1"]?.length > 0 &&
      selectedFilters.push("category_level1");
    selectedUIfilters["category_level2"]?.length > 0 &&
      selectedFilters.push("category_level2");
    selectedUIfilters["category_level3"]?.length > 0 &&
      selectedFilters.push("category_level3");
    selectedUIfilters["region_level1"]?.length > 0 &&
      selectedFilters.push("region_level1");
    selectedUIfilters["region_level2"]?.length > 0 &&
      selectedFilters.push("region_level2");
    selectedUIfilters["region_level3"]?.length > 0 &&
      selectedFilters.push("region_level3");
    selectedUIfilters["entity_level1"]?.length > 0 &&
      selectedFilters.push("entity_level1");
    selectedUIfilters["entity_level2"]?.length > 0 &&
      selectedFilters.push("entity_level2");
    selectedUIfilters["entity_level3"]?.length > 0 &&
      selectedFilters.push("entity_level3");
    selectedUIfilters["entity_level4"]?.length > 0 &&
      selectedFilters.push("entity_level4");
    selectedUIfilters["custom_specification1"]?.length > 0 &&
      selectedFilters.push("custom_specification1");
    selectedUIfilters["custom_specification2"]?.length > 0 &&
      selectedFilters.push("custom_specification2");
    selectedUIfilters["custom_specification3"]?.length > 0 &&
      selectedFilters.push("custom_specification3");
    const request = {
      engagementCode: engagement.engagementCode,
      projectID: project.projectId,
      columnNames: selectedFilters,
      numberOfYears: engagement.foreCastEndYear - engagement.mostRecentYear,
      viewType: "M3-ApplyFilter",
      modifiedBy: user.username,
      counter: emessionFilterCounter,
    };
    dispatch(setAppliedFiltersPayload({ filtersPayload: request }));
    const data = await getReduceDefautViewData({ request });
    dispatch(setReduceDefautViewData({ defaultViewData: data }));
    dispatch(setSimulateFlag({ isSimulate: false }));
    dispatch(setGraphSimulateFlag({ isGraphSimulate: false }));
    dispatch(
      setselectedUIAllFilters({ selectedUIAllFilters: selectedUIfilters })
    );
    action === "Apply" && dispatch(setTableFiltersApplied({ isApplied: true }));
    setLoader(false);
    // setModalFiltersApplied(false);
    onApply();
  };

  // Payload consstruction for applying filters
  const constructPayload = async (filters) => {
    let dropDownMetaData = [];
    const selectedScope = filters.scope.map((x) => {
      return { columnType: "Scope", columnValue: x };
    });
    const selectedCatLevel1 = filters.category_level1.map((x) => {
      return { columnType: "Category_level1", columnValue: x };
    });
    const selectedCatLevel2 = filters.category_level2.map((x) => {
      return { columnType: "Category_level2", columnValue: x };
    });
    const selectedCatLevel3 = filters.category_level3.map((x) => {
      return { columnType: "Category_level3", columnValue: x };
    });
    //Geo Data
    const selectedRegionLevel1 = filters.region_level1.map((x) => {
      return { columnType: "Region_level1", columnValue: x };
    });
    const selectedRegionLevel2 = filters.region_level2.map((x) => {
      return { columnType: "Region_level2", columnValue: x };
    });
    const selectedRegionLevel3 = filters.region_level3.map((x) => {
      return { columnType: "Region_level3", columnValue: x };
    });

    //Org Data
    const selectedEntityLevel1 = filters.entity_level1?.map((x) => {
      return { columnType: "Entity_level1", columnValue: x };
    });
    const selectedEntityLevel2 = filters.entity_level2?.map((x) => {
      return { columnType: "Entity_level2", columnValue: x };
    });
    const selectedEntityLevel3 = filters.entity_level3?.map((x) => {
      return { columnType: "Entity_level3", columnValue: x };
    });
    const selectedEntityLevel4 = filters.entity_level4?.map((x) => {
      return { columnType: "Entity_level4", columnValue: x };
    });
    const selectedCustom1 = filters.custom_specification1.map((x) => {
      return { columnType: "Custom_specification1", columnValue: x };
    });
    const selectedCustom2 = filters.custom_specification2.map((x) => {
      return { columnType: "Custom_specification2", columnValue: x };
    });
    const selectedCustom3 = filters.custom_specification3.map((x) => {
      return { columnType: "Custom_specification3", columnValue: x };
    });

    dropDownMetaData = [
      ...selectedScope,
      ...selectedCatLevel1,
      ...selectedCatLevel2,
      ...selectedCatLevel3,
      ...selectedRegionLevel1,
      ...selectedRegionLevel2,
      ...selectedRegionLevel3,
      ...selectedEntityLevel1,
      ...selectedEntityLevel2,
      ...selectedEntityLevel3,
      ...selectedEntityLevel4,
      ...selectedCustom1,
      ...selectedCustom2,
      ...selectedCustom3,
    ];
    const payload = {
      engagementId: engagement.id,
      engagementCode: engagement.engagementCode,
      name: project.projectName,
      moduleName: "M3-ApplyFilter",
      CreatedBy: user.username,
      modifiedby: user.username,
      dropDownMetaData: dropDownMetaData,
      projectId: project.projectId,
    };
    return payload;
  };

  return (
    <div>
      <div className="emission-filter-modal-content">
        {dropdowns.map((element) => {
          return (
            <div className="emission-filter-modal-content-main">
              <span className="emission-filter-modal-label" key={element.Name}>
                {element.Name}
              </span>
              <div className="emission-filter-modal-content-dropdowns">
                {element.dropdownList.map((dropdown) => {
                  return (
                    <div>
                      <span className="emission-filter-modal-content-dropdowns-label">
                        {dropdown.label}
                      </span>
                      <Select
                        options={getDropdownValues(dropdown.fieldId)}
                        disabled={disableDropdowns(dropdown.label)}
                        value={
                          selectedUIfilters[dropdown.fieldId]
                            ? selectedUIfilters[dropdown.fieldId]
                            : []
                        }
                        onChange={(event) => {
                          handleDropdownChange(
                            event,
                            dropdown.fieldId,
                            dropdown.id
                          );
                        }}
                        multiple={true}
                        showSelectAllButton={true}
                      ></Select>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="emission-filter-modal-footer">
        <Button
          variant="secondary"
          onClick={() => {
            resetDropdowns();
          }}
          disabled={!filtersApplied}
        >
          {reset}
        </Button>
        <LoadingIndicatorEmbed show={showLoader}>
          <Button
            variant="primary"
            onClick={() => applyFilters(selectedUIfilters, "Apply")}
            disabled={!modalFiltersApplied}
          >
            {ApplyBtn}
          </Button>
        </LoadingIndicatorEmbed>
      </div>
    </div>
  );
}

export function EmissionFilterModal({ isFilterOpen, onClose }) {
  return (
    <Modal
      show={isFilterOpen}
      onClose={onClose}
      header={
        <div className="emission-filter-modal-header">
          <span>{filters}</span>
          <Tooltip
            trigger={<EmissionIcon />}
            variant="ghost"
            tooltipText={emissionFilterTooltip}
          ></Tooltip>
        </div>
      }
      body={<ModalBody onApply={onClose}></ModalBody>}
      // className={"emission-motif-modal"}
      className={"emission-gmp-modal"}
    ></Modal>
  );
}
